import React,{useState, useEffect} from 'react'
import { useMediaQuery } from "react-responsive";
import { useLocation,useNavigate } from 'react-router-dom';
import Loader from '../exercise/Loader';
const CarePlixAssessment = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [isLoading, setIsLoading] = useState(true);
    const { state } = useLocation();
    const navigate = useNavigate();

    const extractRetryUrl = (url) => {
      try {
          const urlObj = new URL(url);
          const retryUrl = urlObj.searchParams.get('retry_url');
          if (retryUrl) {
              // Parse the retry URL to get just the pathname
              const parsedRetryUrl = new URL(decodeURIComponent(retryUrl));
              return parsedRetryUrl.pathname;
          }
          return null;
      } catch (error) {
          console.error('Error parsing URL:', error);
          return null;
      }
  };
    useEffect(() => {
      // Handle messages from iframe
      const handleMessage = (event) => {
          // Verify the origin of the message for security
          // Replace with your actual iframe origin
          // Handle retry action from iframe
          if (event.data?.action === 'retry') {
              // Navigate to the user assessment page
              const retryUrl = extractRetryUrl(state?.pwa_link);
              navigate(retryUrl, { replace: true });
          }
      };

      window.addEventListener('message', handleMessage);

      return () => {
          window.removeEventListener('message', handleMessage);
      };
  }, [navigate, state?.pwa_link]);

  return (
    <div className="swasa-iframe-container">
      {isLoading && (
        <Loader/>
      )}
      <iframe
        src={state?.pwa_link}
        width="100%"
        height="100%"
        allow="camera; microphone"
        allowFullScreen={true}
        onLoad={() => setIsLoading(false)}
      ></iframe>
      <style jsx>{`
        .swasa-iframe-container {
          width: 100%;
          height: 100vh;
          overflow: hidden;
        }
      `}</style>
    </div>
  )
}

export default CarePlixAssessment