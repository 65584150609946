import React from "react";
import LungHealthTestNav from "./LungHeathTestNav";
import GaugeComponent from "./GaugeComponent";
import DangerICon from '../../assests/icons/danger.png'
import AppDownloadComponent from "./AppDownloadComponent";
const LungTestSuccessScreen = () => {
  return (
    <div>
      <div>
        <LungHealthTestNav />
      </div>
      <div className="mt-20">
        <p className="text-center text-[#474747] text-xl font-semibold">
          Assessment Complete !
        </p>
        <div className="bg-[#FFF3F3] px-5 py-5 m-5 border-[1px] rounded-2xl">
          <div className="flex justify-between">
            <div>
              <p className="text-lg font-semibold mb-5">
                Your lung health
                <br /> index is <span className="text-[#FC5927]">High !</span>
              </p>
              <button className="bg-[#FFFFFF] border-[1px] rounded-3xl border-[#979797]  px-4 py-3 text-[#979797] text-sm font-semibold">
                Check again
              </button>
            </div>
            <div>
              <GaugeComponent value={5} min={0} max={10} label="High" />
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-3">
          <img src={DangerICon}/>
          <p className="text-base font-medium">Please visit your doctor at the earliest</p>
        </div>
        <div className="fixed bottom-0 px-5 py-5">
          <AppDownloadComponent/>
        </div>
      </div>
    </div>
  );
};

export default LungTestSuccessScreen;
