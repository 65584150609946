import React from 'react';

const GaugeComponent = ({ value, min = 0, max = 100, label = 'High' }) => {
  const angle = ((value - min) / (max - min)) * 180 - 90;

  return (
    <div className="flex flex-col items-center">
      <svg width="160" height="70" viewBox="0 0 200 120">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="red" />
            <stop offset="50%" stopColor="yellow" />
            <stop offset="100%" stopColor="green" />
          </linearGradient>
        </defs>
        <path
          d="M20 100 A80 80 0 0 1 180 100"
          fill="none"
          stroke="url(#gradient)"
          strokeWidth="25"
          strokeLinecap="round"
        />
        <g transform={`rotate(${angle}, 100, 100)`}>
          <path
            d="M95 100 L100 40 L105 100 Z"
            fill="#333"
          />
          <circle cx="100" cy="100" r="8" fill="#666" />
        </g>
      </svg>
      <div className="text-xl font-bold mt-2">{label}</div>
    </div>
  );
};

export default GaugeComponent;