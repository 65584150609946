import React from "react";
import BreatheFree from "../../assests/images/breathefree-logo-new3.png";
import CdhlLogo from "../../assests/images/breathfreebycipla.png";

const LungHealthTestNav = () => {
  return (
    <div className="mt-10">
      <nav className="bg-white fixed w-full z-20 top-0 start-0 border-b md:block border-gray-200">
        <div className="max-w-screen-xl mx-auto p-4 flex items-center">
          <a
            href="https://play.google.com/store/apps/details?id=breathefree.lung.health.asthma.breathing"
            className="flex items-center"
          >
            <img src={CdhlLogo} className="h-9 ml-2" alt="CDHL Logo" />
          </a>
        </div>
      </nav>
    </div>
  );
};

export default LungHealthTestNav;