import React from 'react';
import { Helmet } from 'react-helmet';

const SeoHelmet = ({ title, description }) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
+     {title && <meta name="title" content={title} />}
+     {description && <meta name="description" content={description} />}
      <meta property="og:title" content={title} />
      <meta property="og:image" content="/logo192.png" />
      {process.env.REACT_APP_IS_PROD ? (
        <meta name="robots" content="index,follow" />
          ) : (
          <meta name="robots" content="noindex,nofollow" />
      )}
    </Helmet>
  );
};

export default SeoHelmet;