import React, { useState } from "react";
import { Link } from "react-router-dom";
import premiumLock from "../../assests/icons/premiumLock.png";
import premiumlabel from "../../assests/icons/premium.png";
import { useNavigate } from "react-router-dom";
import play from "../../assests/icons/Play.png";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
const PremiumExerciseCard = ({ item, handleExerciseClick, userIsPremium,isLoggedIn }) => {
  const [isloggedIn, setIsloggedIn] = useState(false);
  const [userPremium, setUserpremium] = useState(false);
  const navigate = useNavigate();
  const access_Token = localStorage.getItem("accesstoken");
  const handleCardClick = (e) => {
    e.preventDefault();

    
    if (!userIsPremium && !isLoggedIn) {
      // Store premium exercise info in localStorage
      localStorage.setItem('fromPremiumExercise', 'true');
      handlePremiumLockedExerciseClick();
      // Redirect to login page
      navigate('/login');
    } else if ( !userIsPremium) {
      handlePremiumLockedExerciseClick();
      // Redirect to subscription page
      navigate('/subscription');
      
    } else {
      handlePremiumLockedExerciseClick();
      // Regular navigation for non-premium or premium users with access
      // navigate(`/exercise-details-page/${exercise.exercise_enum}`, { state: { exercise: exercise } });
      const formattedEnum = item.exercise_enum.replace(/_/g, '-');
      navigate(`/breathing-exercises/${formattedEnum}`, {
            state: { exercise: item },
          });
          handleExerciseClick(item);
    }
  };
  const handlePremiumLockedExerciseClick = () => {
    sendEventsToAnalyticsSystems("exercise_subs_screen", {
      exerciseName: item.exercise_enum,
      // exerciseNumber: index + 1,
      exerciseType: item.exercise_type,
      Source: item.exercise_description,
      utm_source  :'breathing_exercise_web'
    },localStorage.getItem("secondary_uuid"));
  };
  return (
    // <Link
    //   to={`/exercise-details-page/${item.exercise_enum}`}
    //   state={{ exercise: item }}
    //   onClick={() => handleExerciseClick(item)}
    // >
    <div onClick={handleCardClick}>
      <div className="flex flex-col justify-center mb-4">
        <div className=" border-transparent bg-premium-border bg-clip-padding-border border-[1px] bg-origin-padding-border rounded-lg">
          <div className="bg-premium-background w-full rounded-lg ">
            <div className="flex items-center justify-between rounded-lg h-[100px]">
              <div className="relative">
                <img
                  src={`${process.env.REACT_APP_S3_BUCKET_URL}/${item.listing_image_url}`}
                  alt={item.title}
                  className="w-24 h-[100px] object-cover rounded "
                />
                <img src={premiumlabel} className="absolute top-2 left-2" />
              </div>

              <div className="flex flex-col flex-grow ml-4 gap-y-6">
                <div>
                  <p className="text-xs text-[#212121]">{item.title}</p>
                  <p className="text-sm font-semibold text-[#171717]">
                    {item.exercise_description}
                  </p>
                </div>
                <p className="text-[10px] font-semibold text-[#212121]">
                  {item.exercise_time}
                </p>
              </div>
              <button
                className={`${
                  userIsPremium
                    ? "bg-button-bg-color"
                    : "bg-button-lock-bg-color"
                } mr-2 text-white p-3 rounded-full`}
              >
                <img
                  src={`${userIsPremium ? play : premiumLock}`}
                  alt="Premium"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* // </Link> */}
    </div>
  );
};

export default PremiumExerciseCard;
