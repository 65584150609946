import React from 'react';
import googleplaystore from '../../assests/icons/googleplay.png';
import appstore from '../../assests/icons/appstore.png';

const AppDownloadComponent = () => {
  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md max-w-md mx-auto">
      <p className="text-center mb-4 text-gray-800 font-medium">
        To know more about Asthma and COPD risk,
        <br />
        check detailed report on Breathefree app
      </p>
      <div className="flex justify-between space-x-4">
        <a
          href="https://play.google.com/store/apps/details?id=com.breathefree"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center"
        >
          <img src={googleplaystore} alt="Google Play" className="h-14" />
        </a>
        <a
          href="https://apps.apple.com/app/breathefree/id1234567890"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center"
        >
          <img src={appstore} alt="App Store" className="h-14" />
        </a>
      </div>
    </div>
  );
};

export default AppDownloadComponent;