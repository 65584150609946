import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import stopIcon from "../../assests/icons/Rectangle.png";
import CompletedScreen from "./CompletedScreen";
import axios from "axios";
import { BASE_URL,BASE_URL_WEBVIEW } from "../../utils/ApiConstants";
import StopModal from "./StopModal";
import playicon from "../../assests/icons/playicon.png";
import Loader from "./Loader";
import { sendEventsToAnalyticsSystems } from '../../utils/analytics';

const ExerciseVideoView = () => {
  const {exercise_enum}=useParams();
  const originalExerciseEnum = exercise_enum.replace(/-/g, '_');
  const [exerciseDetails, setExerciseDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [selectedDuration, setSelectedDuration] = useState(0);
  // const [selectedAccurateTime, setselectedAccurateTime] = useState(0);
  // const [selectedRounds, setSelectedRounds] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  // const exerciseVideo = location.state?.exercise;
  const { selectedRounds, selectedLevel, selectedDuration,selectedAccurateTime } = location.state;
  
  // const { selectedLevel } = location.state;

  // const [currentVideo, setCurrentVideo] = useState(
  //   `${ImageURL}${exerciseVideo?.instruction_video_url}`
  // );
  // const [isSkipped, setIsSkipped] = useState(false);
  useEffect(() => {
    const fetchExerciseDetails = async () => {
      setLoading(true);
      const access_Token = localStorage.getItem("accesstoken");
      const isLoggedIn = !!access_Token;
      try {
        let url = isLoggedIn
          ? `${BASE_URL}/breathing/exercise/detail`
          : `${BASE_URL_WEBVIEW}/breathing/exercise/detail`;
        const headers = isLoggedIn
          ? {
              accesstoken: access_Token,
              platform: "android",
              appVersion: "5.2",
            }
          : {};
        const response = await axios.post(
          url,
          {
            exercise_enum: originalExerciseEnum,
          },
          { headers: headers }
        );
        setExerciseDetails(response.data);
        const defaultLevel = "Intermediate";
        const defaultRounds = response.data.data.level_information.find(
          (level) => level.level === defaultLevel
        );

        // setSelectedRounds(defaultRounds.rounds);
        // setSelectedDuration(defaultRounds.duration);
        // setselectedAccurateTime(defaultRounds.accurate_duration);
      } catch (error) {
        setError("Failed to fetch exercise details");
      } finally {
        setLoading(false);
      }
    };

    fetchExerciseDetails();
  }, [originalExerciseEnum]);
  const practiceVideoUrl = `${process.env.REACT_APP_S3_BUCKET_URL}/${exerciseDetails?.data?.practice_video_url}`;
  const videoRef = useRef(null);
  const [countdown, setCountdown] = useState(3);
  const [currentRound, setCurrentRound] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isStopModalOpen, setIsStopModalOpen] = useState(false); // For stop modal
  const [selectedOption, setSelectedOption] = useState(""); // Track the selected option
  const [isCompleted, setIsCompleted] = useState(false); // Track completion
  const [hasStarted, setHasStarted] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const startButtonRef = useRef(null);
  const [exerciseStarted, setExerciseStarted] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  // const handleVideoEnd = () => {
  //   if (currentRound < selectedRounds - 1) {
  //     setCurrentRound(currentRound + 1);
  //     videoRef.current.currentTime = 0;
  //     videoRef.current.play();
  //   } else {
  //     setIsPlaying(false);
  //     setIsCompleted(true); // Mark as completed
  //   }
  // };
  // const handleVideoEnd = () => {
  //   if (currentRound < selectedRounds - 1) {
  //     setCurrentRound(currentRound + 1);
  //     if (videoRef.current) {
  //       videoRef.current.currentTime = 0;
  //       videoRef.current.play();
  //     }
  //   } else {
  //     setIsPlaying(false);
  //     setIsCompleted(true);
  //   }
  // };
  // const handleVideoEnd = () => {
  //   if (currentRound < selectedRounds - 1) {
  //     setCurrentRound(currentRound + 1);
  //     if (videoRef.current) {
  //       videoRef.current.currentTime = 0;
  //       videoRef.current.play();
  //     }
  //   } else {
  //     setIsPlaying(false);
  //     setIsCompleted(true);
  //     // Update completed exercise count
  //     const count = parseInt(localStorage.getItem('completedExerciseCount') || '0');
  //     localStorage.setItem('completedExerciseCount', (count + 1).toString());
  //   }
  // };

  const handleVideoEnd = () => {
    if (currentRound < selectedRounds - 1) {
      setCurrentRound(currentRound + 1);
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    } else {
      setIsPlaying(false);
      setIsCompleted(true);
      
      // Check if the exercise is not premium (i.e., regular)
      const accessToken = localStorage.getItem('accesstoken');
      // if (!localStorage.getItem('accesstoken') && !exerciseDetails?.data?.is_premium) {
      //   const count = parseInt(localStorage.getItem('completedRegularExerciseCount') || '0');
      //   const newCount = count + 1;
      //   localStorage.setItem('completedRegularExerciseCount', newCount.toString());
      // }else{
      //   localStorage.setItem('completedRegularExerciseCount', '0');
      // }
      if (!accessToken) {
        // User is not logged in
        if (!exerciseDetails?.data?.is_premium) {
          // Exercise is not premium
          const count = parseInt(localStorage.getItem('completedRegularExerciseCount') || '0');
          const newCount = count + 1;
          localStorage.setItem('completedRegularExerciseCount', newCount.toString());
        }
      } else {
        // User is logged in, ensure completedRegularExerciseCount is 0
        localStorage.setItem('completedRegularExerciseCount', '0');
      }
    
    }
  };
  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };
  const handleStop = () => {
    setIsPlaying(false);
    setIsPaused(true);
    setIsStopModalOpen(true);
    if (videoRef.current) {
      videoRef.current.pause();
    }
    sendEventsToAnalyticsSystems("exercise_stop_modal", {
      exerciseName: exerciseDetails?.data?.exercise_enum,
      // exerciseId: exercise.exercise_id,
      exerciseType: exerciseDetails?.data?.exercise_type,
      level:selectedLevel,
      utm_source  :'breathing_exercise_web'
    },localStorage.getItem("secondary_uuid"));
  };
  useEffect(() => {
    sendEventsToAnalyticsSystems("exercise_start_screen",{
      exerciseName: exerciseDetails?.data?.exercise_enum,
      // exerciseId: exercise.exercise_id,
      exerciseType: exerciseDetails?.data?.exercise_type,
      level:selectedLevel,
      utm_source  :'breathing_exercise_web'
    },localStorage.getItem("secondary_uuid"));
    
  }, []);
  // const handleStop = () => {
  //   stopPlaying();
  //   setIsStopModalOpen(true);
  // };

  // const handleResume = () => {
  //   setIsStopModalOpen(false);
  //   setIsPaused(false);
  //   setIsPlaying(true);
  //   if (hasStarted) {
  //     if (videoRef.current) {
  //       videoRef.current.play();
  //     }
  //   } else {
  //     startCountdown();
  //   }
  // };
  const handleResume = () => {
    setIsStopModalOpen(false);
    setIsPaused(false);
    startPlaying();
  };

  const startCountdown = () => {
    setHasStarted(true);
    setCountdown(3);
    setIsPaused(false);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setIsStopModalOpen(false);
    navigate(-1); // Go back to the previous page
  };

  // useEffect(() => {
  //   if (hasStarted && !isPaused && countdown !== null && countdown > 0) {
  //     const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
  //     return () => clearTimeout(timer);
  //   } else if (hasStarted && !isPaused && countdown === 0) {
  //     const timer = setTimeout(() => {
  //       setCountdown(null);
  //       setIsPlaying(true);
  //     }, 1000); // Display "Go!" for 1 second
  //     return () => clearTimeout(timer);
  //   }
  // }, [countdown, hasStarted, isPaused]);

  // useEffect(() => {
  //   startCountdown();
  // }, []);

  // const playVideoWithAudio = () => {
  //   if (videoRef.current) {
  //     videoRef.current.muted = isMuted;
  //     videoRef.current.play().then(() => {
  //       console.log("Video playing with audio");
  //       setIsPlaying(true);
  //     }).catch(error => {
  //       console.error("Error playing video with audio:", error);
  //       videoRef.current.muted = true;
  //       setIsMuted(true);
  //       videoRef.current.play().catch(mutedError => {
  //         console.error("Error playing muted video:", mutedError);
  //       });
  //     });
  //   }
  // };
  // const startExercise = () => {
  //   setExerciseStarted(true);
  //   setHasStarted(true);
  //   setIsPlaying(true);
  //   if (videoRef.current) {
  //     playVideoWithAudio();
  //   }
  // };
  // useEffect(() => {
    
  //   sendEventsToAnalyticsSystems("exercise_screen", {
  //     exerciseName: exerciseVideo?.exercise_enum,
  //     exerciseType: exerciseVideo?.exercise_type,
  //     level: selectedLevel,
  //   });
    
  // }, [])
  
  const startPlaying = () => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
          sendEventsToAnalyticsSystems("exercise_started", {
            exerciseName: exerciseDetails?.data?.exercise_enum,
            exerciseType: exerciseDetails?.data?.exercise_type,
            level: selectedLevel,
            utm_source  :'breathing_exercise_web'
          },localStorage.getItem("secondary_uuid"));

        })
        .catch((error) => {
          console.error("Error playing video:", error);
        });
    }
  };

  const stopPlaying = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  // const playVideoWithAudio = () => {
  //   if (videoRef.current) {
  //     videoRef.current.muted = isMuted;
  //     videoRef.current.play().then(() => {
  //       console.log("Video playing with audio");
  //       setIsPlaying(true);
  //     }).catch(error => {
  //       console.error("Error playing video with audio:", error);
  //       // Fallback to muted autoplay if unmuted autoplay fails
  //       videoRef.current.muted = true;
  //       setIsMuted(true);
  //       videoRef.current.play().catch(mutedError => {
  //         console.error("Error playing muted video:", mutedError);
  //       });
  //     });
  //   }
  // };
  // useEffect(() => {
  //     if (isVideoReady && videoRef.current && countdown === null) {

  //       playVideoWithAudio();
  //     }
  //   }, [isVideoReady, countdown]);
  // useEffect(() => {
  //   if (isVideoReady && countdown === null && startButtonRef.current) {
  //     setTimeout(() => {
  //       startButtonRef.current.click();
  //     }, 1000);
  //   }
  // }, [isVideoReady, countdown]);
  if (loading) {
    return <Loader />;
  }
  
  return (
    <>
      {/* <div className="pt-6 flex items-stretch flex-col overflow-x-hidden ">
        {!isCompleted && (
          <div className="flex-none">
            <h3 className=" text-center text-xl font-semibold">
              {exerciseVideo?.exercise_description}
            </h3>
            <p className="font-medium text-center text-base">
              {`${selectedLevel} Exercise`}{" "}
            </p>
            {countdown !== null ? (
              <p className="text-center">Starting In</p>
            ) : (
              ""
            )}
          </div>
        )}

        {countdown !== null ? (
          <>
            <div className="w-full flex-1 flex justify-center items-center">
              <h1 className="text-[10rem] w-full text-center italic leading-[15rem] text-[#89BE5F] font-bold">
                {countdown === 0 ? "Go!" : countdown}
              </h1>
            </div>
          </>
        ) : isCompleted ? (
          <CompletedScreen
            exerciseVideo={exerciseVideo}
            selectedLevel={selectedLevel}
            selectedRounds={selectedRounds}
            selectedDuration={selectedDuration}
            onFinish={() => navigate(-2)}
          />
        ) : (
          <>
            <p className="text-center mt-2">
              <span className="font-bold">Round {currentRound + 1} of</span>{" "}
              {selectedRounds}
            </p>
            <button 
            ref={startButtonRef}
                  onClick={playVideoWithAudio}
                  className="mt-4 px-4 py-2 bg-[#72B53D] text-white rounded-full"
                >
                  Start Exercise
                </button>
            
            <div className="relative flex flex-col items-center justify-center h-full w-full">
            {!isCompleted && countdown === null && (
              <div className={`absolute top-4 ${isStopModalOpen ? '' :'z-20'}`}>
                <button
                  onClick={toggleMute}
                  className="px-3 py-1 bg-white text-[#72B53D] border border-[#72B53D] rounded-full text-sm"
                >
                  {!isMuted ? "Unmute" : "Mute"}
                </button>
              </div>
            )}
              <video
                ref={videoRef}
                className="h-full w-full rounded-lg"
                controls={false}
                key={practiceVideoUrl}
                onEnded={handleVideoEnd}
                playsInline
              >
                <source src={practiceVideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>

            </div>
            
          </>
        )}
        {!isCompleted && (
              <div className="p-4 fixed w-full bottom-0">
                <button
                  className="px-4 py-2 flex justify-center items-center gap-2 bg-white text-[#72B53D] border-2 border-[#72B53D] text-xl rounded-full w-full"
                  onClick={handleStop}
                >
                  <img src={stopIcon} alt="Stop" />
                  Stop
                </button>
              </div>
            )}
      </div> */}
      {/* <div className="pt-6 flex items-stretch flex-col overflow-x-hidden h-screen">
    {
    !exerciseStarted ? (
      <div className="w-full flex-1 flex justify-center items-center">
        <button 
          onClick={startExercise}
          className="px-4 py-2 bg-[#72B53D] text-white rounded-full text-xl"
        >
          Start Exercise
        </button>
      </div>
    ) :
    
    isCompleted ? (
      <CompletedScreen
        exerciseVideo={exerciseVideo}
        selectedLevel={selectedLevel}
        selectedRounds={selectedRounds}
        selectedDuration={selectedDuration}
        onFinish={() => navigate(-2)}
      />
    ) : (
      <>
        <div className="flex-none">
          <h3 className="text-center text-xl font-semibold">
            {exerciseVideo?.exercise_description}
          </h3>
          <p className="font-medium text-center text-base">
            {`${selectedLevel} Exercise`}
          </p>
        </div>
        <p className="text-center mt-2">
          <span className="font-bold">Round {currentRound + 1} of</span>{" "}
          {selectedRounds}
        </p>
        <div className="relative flex flex-col items-center justify-center flex-1 w-full">
          <video
            ref={videoRef}
            className="h-full w-full rounded-lg"
            controls={false}
            key={practiceVideoUrl}
            onEnded={handleVideoEnd}
            playsInline
          >
            <source src={practiceVideoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </>
    )}

    {!isCompleted && exerciseStarted && (
      <div className="p-4 fixed w-full bottom-0">
        <button
          className="px-4 py-2 flex justify-center items-center gap-2 bg-white text-[#72B53D] border-2 border-[#72B53D] text-xl rounded-full w-full"
          onClick={handleStop}
        >
          <img src={stopIcon} alt="Stop" />
          Stop
        </button>
      </div>
    )}
  </div> */}

      <div className="pt-6 flex items-stretch flex-col overflow-x-hidden">
        {!isCompleted && (
          <div className="flex-none">
            <h3 className="text-center text-xl font-semibold">
              {exerciseDetails?.data?.exercise_description}
            </h3>
            <p className="font-medium text-center text-base">
              {`${selectedLevel} Exercise`}
            </p>
          </div>
        )}

        {!isCompleted && (
          <div className="p-4 fixed w-full bottom-0">
            {isPlaying ? (
              <button
                className="px-4 py-2 flex justify-center items-center gap-2 bg-white text-[#72B53D] border-2 border-[#72B53D] text-xl rounded-full w-full"
                onClick={handleStop}
              >
                <img src={stopIcon} alt="Stop" />
                Stop
              </button>
            ) : (
              <button
                className={`px-4 py-2 flex justify-center items-center gap-2 bg-[#72B53D] text-white border-2 border-[#72B53D] text-xl rounded-full w-full ${
                  isVideoLoading ? "cursor-not-allowed" : ""
                }`}
                onClick={startPlaying}
              >
                <div className="flex items-center gap-2 justify-center">
                  <img src={playicon} />
                  Start
                </div>
              </button>
            )}
          </div>
        )}
        {isCompleted ? (
          <div className="absolute inset-0 bg-white flex items-center justify-center">
            <CompletedScreen
              exerciseVideo={exerciseDetails?.data}
              selectedLevel={selectedLevel}
              selectedRounds={selectedRounds}
              selectedDuration={selectedDuration}
              selectedAccurateTime={selectedAccurateTime}
              onFinish={() => navigate(-2)}
            />
          </div>
        ) : (
          <>
            <p className="text-center mt-2">
              <span className="font-bold">Round {currentRound + 1} of</span>{" "}
              {selectedRounds}
            </p>
            <div className="relative flex flex-col items-center justify-center flex-1 w-full">
              {isVideoLoading && <Loader />}
              <video
                ref={videoRef}
                className="h-full w-full rounded-lg"
                controls={false}
                key={practiceVideoUrl}
                onEnded={handleVideoEnd}
                onLoadedData={() => setIsVideoLoading(false)}
                onCanPlay={() => setIsVideoLoading(false)}
                onLoadedMetadata={() => setIsVideoLoading(false)}
                playsInline
              >
                <source src={`${practiceVideoUrl}#t=0.001`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </>
        )}
      </div>

      {isStopModalOpen && (
        <StopModal
          isOpen={isStopModalOpen}
          onClose={() => setIsStopModalOpen(false)}
          onOptionChange={handleOptionChange}
          exerciseVideo={exerciseDetails?.data}
          selectedOption={selectedOption}
          selectedLevel={selectedLevel}
          onResume={handleResume}
        />
      )}
    </>
  );
};

export default ExerciseVideoView;
