import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import CareplixBanner1En from "../../assests/images/careplixBanner1_en.png";
import CareplixBanner2En from "../../assests/images/careplixBanner2_en.png";
import CareplixBanner1Hi from "../../assests/images/careplixBanner1_hi.png";
import CareplixBanner2Hi from "../../assests/images/careplixBanner2_hi.png";

const CarePlixCarousel = () => {
  const [carouselHeight, setCarouselHeight] = useState('40vh');
  const [currentLanguage, setCurrentLanguage] = useState('EN');
  const [swiperInstance, setSwiperInstance] = useState(null);

  // Listen for language changes in localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const newLanguage = localStorage.getItem("languageSelectionCarePlix") || "EN";
      setCurrentLanguage(newLanguage);
      
      // Reset swiper to first slide when language changes
      if (swiperInstance) {
        swiperInstance.slideTo(0);
      }
    };

    // Initial language setup
    handleStorageChange();

    // Listen for changes
    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('languageChange', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('languageChange', handleStorageChange);
    };
  }, [swiperInstance]);

  useEffect(() => {
    const calculateCarouselHeight = () => {
      const viewportHeight = window.innerHeight;
      const headerHeight = 10;
      const footerHeight = 10;
      const availableHeight = viewportHeight - headerHeight - footerHeight;
      setCarouselHeight(`${availableHeight}px`);
    };

    calculateCarouselHeight();
    window.addEventListener("resize", calculateCarouselHeight);

    return () => {
      window.removeEventListener("resize", calculateCarouselHeight);
    };
  }, []);

  const staticImages = {
    EN: [
      {
        id: 1,
        image: CareplixBanner1En,
        title: 'First Image (English)'
      },
      {
        id: 2,
        image: CareplixBanner2En,
        title: 'Second Image (English)'
      }
    ],
    HI: [
      {
        id: 1,
        image: CareplixBanner1Hi,
        title: 'First Image (Hindi)'
      },
      {
        id: 2,
        image: CareplixBanner2Hi,
        title: 'Second Image (Hindi)'
      }
    ]
  };

  const currentImages = staticImages[currentLanguage] || staticImages.EN;

  return (
    <div className="carousel-container px-4 mt-2">
      <Swiper
        modules={[Pagination, Autoplay]}
        pagination={{
          clickable: true,
          el: ".swiper-pagination",
          type: "bullets",
        }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        className="flex flex-col items-center h-[calc(100vh_-_450px)]"
        onSwiper={setSwiperInstance}
      >
        {currentImages.map((item) => (
          <SwiperSlide
            key={`${item.id}-${currentLanguage}`}
            className="flex flex-col items-center "
          >
            <div className="flex justify-center flex-col items-center h-full">
              <img
                src={item.image}
                alt={item.title}
                className="w-full max-w-full h-full max-h-full object-contain"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-pagination mt-4"></div>
      <style jsx global>{`
        .swiper-pagination {
          position: static;
          margin-top: 0px;
        }
        .swiper-pagination-bullet {
          background: #ccc;
        }
        .swiper-pagination-bullet-active {
          background: #72b53d;
        }
      `}</style>
    </div>
  );
};

export default CarePlixCarousel;