import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CarePlixCarousel from "./CarePlixCorousel";
import LungHealthTestNav from "../LungHealthTest/LungHeathTestNav";
import { BASE_COMMON_URL } from "../../utils/ApiConstants";
import axios from "axios";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
const CareplixHomeScreen = () => {
  const [language, setLanguage] = useState("English");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [langCode, setLangCode] = useState("EN");
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accesstoken");
  const [urlRedirect, setUrlRedirect] = useState("");
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const pathname = window.location.pathname;
  const educator = pathname.split('/').pop();
  const changeLanguage = (lang, code) => {
    localStorage.setItem("languageSelectionCarePlix", code);
    setLanguage(lang);
    setLangCode(code);
    setIsDropdownOpen(false);
    window.dispatchEvent(new Event('languageChange'));
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectValue = searchParams.get("utm_source");
    if (redirectValue) {
      setUrlRedirect(redirectValue);
    }
    // else{
    //   localStorage.removeItem('languageSelection');
    // }
  }, []);
  useEffect(() => {
    if (!localStorage.getItem("languageSelectionCarePlix")) {
      localStorage.setItem("languageSelectionCarePlix", "EN");
    } else {
      // If a language is already set, use it
      const storedLang = localStorage.getItem("languageSelectionCarePlix");
      setLangCode(storedLang);
      setLanguage(storedLang === "EN" ? "English" : "हिंदी");
    }
    const payload = {
      channel: "Web",
      language: langCode,
      screen_name:'INTRO',
      screening_tool:'careplix',
      utm_source: urlRedirect,
    };
    sendEventsToAnalyticsSystems("careplix_intro_screen", payload,localStorage.getItem("secondary_uuid"));
  }, [langCode]);
  const handleStartAssessment = async () => {
    const profileDetails = localStorage.getItem('profileDetails');
    const baseUrl = process.env.REACT_APP_WEBSITE_URL;
    const redirectUrl = `${baseUrl}/lung-health-assessment/${educator}`;
    if (accessToken && profileDetails && JSON.parse(profileDetails)) {
      try {
        const storedLang = localStorage.getItem("languageSelectionCarePlix");
        const carePlixResponse = await axios.get(
          `${BASE_COMMON_URL}/api/v1/careplix-services/careplix-scan-details?lang=${storedLang}&platform=web&retry_url=${encodeURIComponent(redirectUrl)}`,
          {
            headers: {
              'accesstoken': accessToken,
            }
          }
        );
        const pwa_link = carePlixResponse?.data?.data?.pwa_link;
        navigate("/lung-health-assessment/assessment", {
          state: {
            pwa_link: pwa_link
          }
        });
      } catch (error) {
        console.error("Error fetching CarePlix details:", error);
      
        // Clear localStorage if error status is 400
        if (error.response?.status === 400) {
          localStorage.clear();
        }
        
        navigate("/login?url_redirect=careplix");
      }
    } else if (accessToken) {
      navigate("/lung-health-assessment/profile",{state:{linkFrom : educator}});
    } else {
      navigate(`/login?url_redirect=careplix`,{state:{linkFrom : educator}});
    }
    const payload = {
      channel: "web",
      tap_type: "Next",
      language: langCode,
      screen_name:'INTRO'
        };
    sendEventsToAnalyticsSystems("careplix_intro_screen_tap", payload,localStorage.getItem("secondary_uuid"));
  };
  const isHindiLang = localStorage.getItem("languageSelectionCarePlix") === "HI";
  return (
    <div className="flex flex-col">
      <LungHealthTestNav />
      <div>
        <main className="flex-grow px-4 sm:px-6 lg:px-8 mt-8 sm:mt-10">
          <div className="relative mb-1 hidden">
            <button
              onClick={toggleDropdown}
              className="flex items-center text-[#72B53D] ml-auto border-b-[#72B53D] border-b-2 absolute right-0 z-[999] top-[-50px]"
            >
              <span className="mr-1 ">{language}</span>
              <span>
                <svg
                  width="15"
                  height="8"
                  viewBox="0 0 15 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 0.689453H1.82764L7.41384 6.89635L13 0.689453Z"
                    fill="#72B53D"
                    stroke="#72B53D"
                    stroke-width="1.24138"
                  />
                </svg>
              </span>
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-24 bg-white border border-gray-200 rounded shadow-lg z-10">
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  onClick={() => changeLanguage("English", "EN")}
                >
                  English
                </button>
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  onClick={() => changeLanguage("हिंदी", "HI")}
                >
                  हिंदी
                </button>
              </div>
            )}
          </div>
        </main>
        <h1 className="text-center text-2xl sm:text-3xl font-semibold text-[#434343] ">
          {!isHindiLang ? `Lung health test` : `फेफड़ों का स्वास्थ्य परीक्षण`}
        </h1>
        <CarePlixCarousel />
      </div>
      <div className="px-4 text-center sm:px-6 lg:px-8">
      {!isHindiLang ? (
            <p>
              Get your lung health score instantly! Simply scan your voice and
              finger to unlock insights about your respiratory wellness.
            </p>
          ) : (
            <p>
             तुरंत अपना फेफड़ों का स्वास्थ्य स्कोर प्राप्त करें! 
             अपने श्वसन स्वास्थ्य के बारे में जानकारी प्राप्त करने के लिए बस अपनी आवाज़ और उंगली को स्कैन करें।
            </p>
          )}
      </div>
      <footer className="fixed bottom-2 mt-auto px-4 sm:px-6 lg:px-8">
        <div className="text-[10px] text-[#ADADAD] border-inherit border-[1px] px-4 py-2 rounded-md mb-3">
          {!isHindiLang ? (
            <p>
              <span className="font-bold">Disclaimer:</span> Brought to you by
              Cipla Digital Health Limited for information and awareness
              purposes. Not intended as a substitute for professional medical
              advice.
            </p>
          ) : (
            <p>
              <span className="font-bold"> अस्वीकरण:</span> यह सिप्ला डिजिटल
              हेल्थ लिमिटेड द्वारा आपके लिए सूचना और जागरूकता उद्देश्यों के लिए
              लाया गया है। इसका उद्देश्य पेशेवर चिकित्सा सलाह का विकल्प नहीं है।
            </p>
          )}
        </div>
        <button
          onClick={handleStartAssessment}
          className="flex justify-between items-center px-10 py-3 bg-[#72B53D] text-white font-semibold text-lg rounded-full w-full"
        >
          {!isHindiLang ? (
            <span>Next</span>
          ) : (
            <span>आगे बढ़े</span>
          )}
          <span>{`>`}</span>
        </button>
      </footer>
    </div>
  );
};

export default CareplixHomeScreen;
