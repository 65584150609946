import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Lottie from "lottie-react";
import LungHealthTestNav from "./LungHeathTestNav";
import SeoHelmet from "../../utils/SeoHelmet";
import swaasapaymentEN from "../../assests/images/swaasa_payment_en.png";
import swaasapaymentHI from "../../assests/images/swaasa_payment_hi.png";
import tickmark from "../../assests/images/tickmark.png";
import { BASE_COMMON_URL } from "../../utils/ApiConstants";
import sucessslottie from "../../assests/json/Successful.json";
import failurelottie from "../../assests/json/Failure.json";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
const SwaasaPaymentFlow = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [swaasaPlans, setSwaasaPlans] = useState([]);
  const navigate = useNavigate();
  // const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  // const [showPaymentFailurePopup, setShowPaymentFailurePopup] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const { state } = useLocation();
  const benefitsList = [
    {
      icon: tickmark,
      altText: "Insights",
      englishText: "Valuable Insights",
      hindiText: "मूल्यवान जानकारियाँ",
    },
    {
      icon: tickmark,
      altText: "Anytime, Anywhere",
      englishText: "Anytime, Anywhere",
      hindiText: "कभी भी, कहीं भी",
    },
    {
      icon: tickmark,
      altText: "Fast & Simple",
      englishText: "Fast & Simple",
      hindiText: "तेज़ और आसान",
    },
  ];

  const isHindiLang = localStorage.getItem("languageSelection") === "HI";
  const access_Token = localStorage.getItem("accesstoken");

  const requestOptions = {
    headers: {
      accesstoken: access_Token,
    },
  };

  const fetchSwaasaPlans = () => {
    axios
      .get(`${BASE_COMMON_URL}/api/v5/vitals-tracking/product-families`, {
        params: {
          service_type: "SWAASA_SCAN",
        },
        ...requestOptions,
      })
      .then((response) => {
        if (response.data.response.code === 200) {
          const swaasaPlan = response.data.data.find(
            (plan) => plan.service_name === "Swaasa Scan"
          );

          setSwaasaPlans(swaasaPlan);
          // Automatically select the first plan
          setSelectedPlan(swaasaPlan?.product_family_plans_onetime[0]);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          navigate("/login?url_redirect=swaasa");
        } else {
          console.error("An error occurred:", error);
        }
      });
  };

  useEffect(() => {
    fetchSwaasaPlans();
  }, []);
  const profileDetails = JSON.parse(localStorage.getItem("profileDetails"));
  useEffect(() => {
    const payload = {
      channel: "Web",
      language: localStorage.getItem("languageSelection"),
      mobile: localStorage.getItem("mobileNumber"),
      user_id: localStorage.getItem("secondary_uuid"),
      screening_tool: "swaasa",
      name: profileDetails?.full_name,
      age: profileDetails?.age,
      gender: profileDetails?.gender,
      scan_amount: selectedPlan?.final_pricing,
    };

    sendEventsToAnalyticsSystems(
      "swaasa_payment_initiate",
      payload,
      localStorage.getItem("secondary_uuid")
    );
  }, [selectedPlan]);
  const createRazorpayOrder = async () => {
    try {
      const response = await fetch(
        `${BASE_COMMON_URL}/api/v5/payment/checkout?platform=web`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accesstoken: access_Token,
          },
          body: JSON.stringify({
            id: selectedPlan.id,
            plan_name: selectedPlan.plan_name,
            internal_plan_name: selectedPlan.internal_plan_name,
            description: selectedPlan.description,
            paid: selectedPlan.paid,
            billing_frequency: selectedPlan.billing_frequency,
            display_pricing: selectedPlan.display_pricing,
            discount: selectedPlan.discount,
            discountedpricing: selectedPlan.discountedpricing,
            final_pricing: selectedPlan.final_pricing,
            product_type: "SWAASA_SCAN",
            amount: selectedPlan.final_pricing,
            currency: "INR",
            family_plan_id: selectedPlan.id,
            _checkout: "1",
            payment_type: "prepay",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create Razorpay order");
      }

      return await response.json();
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
      throw error;
    }
  };
  const sendPaymentStatusEvent = (status) => {
    const payload = {
      channel: "Web",
      language: localStorage.getItem("languageSelection"),
      mobile: localStorage.getItem("mobileNumber"),
      user_id: localStorage.getItem("secondary_uuid"),
      screening_tool: "swaasa",
      name: profileDetails?.full_name,
      age: profileDetails?.age,
      gender: profileDetails?.gender,
      scan_amount: selectedPlan.final_pricing,
      status: status
    };

    sendEventsToAnalyticsSystems(
      "swaasa_payment_status",
      payload,
      localStorage.getItem("secondary_uuid")
    );
  };

  
  const handleStartPayment = async () => {
    if (state?.isfreemium) {
      
      try {
        const accessToken = localStorage.getItem("accesstoken");
        const paidStatusResponse = await axios.get(
          `${BASE_COMMON_URL}/api/v1/swaasa-services/swaasa-scan-status`,
          {
            headers: {
              accesstoken: accessToken,
            },
          }
        );
  
        if (paidStatusResponse?.data?.data?.free_scan_available) {
          navigate("/lung-health-test/assessment", {
            state: {
              pwa_link: state?.pwa_link,
            },
          });
          return;
        }
      } catch (error) {
        console.error("Error checking paid status:", error);
        alert("Unable to verify scan status. Please try again.");
        return;
      }
    }
    try {
      const orderResponse = await createRazorpayOrder();
      const order = orderResponse?.data;

      if (order && order.order_id) {
        sendEventsToAnalyticsSystems(
          "swaasa_payment_initiate_tap",
          {
            channel: "Web",
            language: localStorage.getItem("languageSelection"),
            mobile: localStorage.getItem("mobileNumber"),
            user_id: localStorage.getItem("secondary_uuid"),
            screening_tool: "swaasa",
            name: profileDetails?.full_name,
            age: profileDetails?.age,
            gender: profileDetails?.gender,
            scan_amount: selectedPlan.final_pricing,
            tap_type: "Take Test",
          },
          localStorage.getItem("secondary_uuid")
        );

        const options = {
          key: process.env.REACT_APP_RAZORPAY_API_KEY,
          amount: order.amount,
          currency: order.currency,
          name: "Breathefree",
          description: "Lung Health Test",
          order_id: order.order_id,
          handler: async function (response) {
            try {
              const verifyResponse = await axios.post(
                `${BASE_COMMON_URL}/api/v5/payment/verify-signature`,
                {
                  payment_id: response.razorpay_payment_id,
                  order_id: response.razorpay_order_id,
                  signature: response.razorpay_signature,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    accesstoken: access_Token,
                  },
                }
              );

              if (verifyResponse.data.response.status === "success") {
                // setShowPaymentPopup(true);
                setPaymentStatus("success");
                sendPaymentStatusEvent("success");
              } else {
                setPaymentStatus("failed");
                sendPaymentStatusEvent("failed");
                // setShowPaymentFailurePopup(true);
                console.error("Verification failed:", verifyResponse.data);
                //   alert("Payment verification failed. Please contact support.");
              }
            } catch (error) {
              setPaymentStatus("failed");
              sendPaymentStatusEvent("failed");
              console.error(
                "Error during verification:",
                error.response?.data || error.message
              );
              handlePaymentError(error, response.razorpay_order_id);
            }
          },
          modal: {
            ondismiss: async function () {
              console.log("Payment cancelled by user");
              try {
                const cancelResponse = await axios.post(
                  `${BASE_COMMON_URL}/api/v5/payment/verify-signature`,
                  {
                    error: "Payment cancelled by user",
                    order_id: order.order_id,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      accesstoken: access_Token,
                    },
                  }
                );
                console.log("Cancellation reported:", cancelResponse.data);
                setPaymentStatus("failed");
                sendPaymentStatusEvent("failed");
              } catch (error) {
                console.error("Error reporting cancellation:", error);
              }
            },
          },
          prefill: {
            email: order.prefill?.email,
            contact: order.prefill?.contact,
            name: order.prefill?.name,
          },
          theme: { color: "#72B53D" },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } else {
        console.error("Invalid order response:", orderResponse);
        alert("Failed to create order. Please try again.");
      }
    } catch (error) {
      console.error("Error in handleStartPayment:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handlePaymentError = async (error, orderId) => {
    console.error("Payment error:", error);
    try {
      const errorResponse = await axios.post(
        `${BASE_COMMON_URL}/api/v5/payment/verify-signature`,
        {
          error: error.message || "Payment failed",
          order_id: orderId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accesstoken: access_Token,
          },
        }
      );
      console.log("Error reported:", errorResponse.data);
      alert("Payment failed. Please try again.");
    } catch (reportError) {
      console.error("Error reporting payment failure:", reportError);
    }
  };
  const handleStartTest = () => {
    navigate("/lung-health-test/assessment", {
      state: {
        pwa_link: state?.pwa_link,
      },
    });
    const payload = {
      channel: "Web",
      language: localStorage.getItem("languageSelection"),
      mobile: localStorage.getItem("mobileNumber"),
      user_id: localStorage.getItem("secondary_uuid"),
      screening_tool: "swaasa",
      name: profileDetails?.full_name,
      age: profileDetails?.age,
      gender: profileDetails?.gender,
      scan_amount: selectedPlan?.final_pricing,
    };

    sendEventsToAnalyticsSystems(
      "swaasa_retest_start_tap",
      payload,
      localStorage.getItem("secondary_uuid")
    );

  };
  const handleCloseModal = () => {
    setPaymentStatus(null);
    const payload = {
      channel: "Web",
      language: localStorage.getItem("languageSelection"),
      mobile: localStorage.getItem("mobileNumber"),
      user_id: localStorage.getItem("secondary_uuid"),
      screening_tool: "swaasa",
      name: profileDetails?.full_name,
      age: profileDetails?.age,
      gender: profileDetails?.gender,
      scan_amount: selectedPlan?.final_pricing,
    };

    sendEventsToAnalyticsSystems(
      "swaasa_payment_failed_tap",
      payload,
      localStorage.getItem("secondary_uuid")
    );
  };
  return (
    <>
      <SeoHelmet
        title="Lung Health Test"
        description="Quick and easy assessment for lung health screening"
      />
      <div className="flex flex-col">
        <LungHealthTestNav />

        <main className="flex-grow px-4 sm:px-6 lg:px-8 mt-8 sm:mt-10">
          <div className="max-w-md mx-auto mb-4 flex justify-center">
            <img
              src={!isHindiLang ? swaasapaymentEN : swaasapaymentHI}
              alt="Swasa Art"
              className="w-[60%] h-[15%] object-contain"
              loading="eager"
              onError={(e) => {
                e.target.onerror = null;
                console.log("Image failed to load:", e);
              }}
            />
          </div>

          <p className="font-normal text-[#424242] text-base pl-5 mb-4 mt-4">
            {!isHindiLang
              ? `Continue to take charge of your lung health with our digital test which is simple, easy and effective for just ₹15 costing you less than a single cup of tea/coffee.`
              : `हमारे डिजिटल टेस्ट के साथ, अपने फेफड़ों के स्वास्थ्य की देखभाल जारी रखें। यह सरल, आसान और प्रभावी है, और सिर्फ ₹15 में उपलब्ध है, जो एक कप चाय/कॉफी से भी सस्ता है।`}
          </p>

          <div className="pl-5">
            <p className="text-xl font-semibold mb-3">
              {!isHindiLang ? `Benefits` : `लाभ`} -{" "}
            </p>
            <ul className="space-y-2">
              {benefitsList.map((benefit, index) => (
                <li
                  key={index}
                  className="flex items-center gap-3 text-[#515151] font-semibold text-base mb-3"
                >
                  <img
                    src={benefit.icon}
                    alt={benefit.altText}
                    className="w-6 h-6"
                  />
                  {!isHindiLang ? benefit.englishText : benefit.hindiText}
                </li>
              ))}
            </ul>
          </div>
        </main>
        <footer className="fixed bottom-7 mt-auto px-4 sm:px-6 lg:px-8 w-full">
          <button
            onClick={handleStartPayment}
            className="flex justify-center text-center items-center px-10 py-3 bg-[#72B53D] text-white font-semibold text-lg rounded-full w-full"
          >
            {!isHindiLang ? (
              <span>Take test at ₹ {selectedPlan?.final_pricing}/-</span>
            ) : (
              <span>केवल ₹{selectedPlan?.final_pricing} में टेस्ट करें।</span>
            )}
          </button>
        </footer>
        {paymentStatus && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <div className="flex justify-center">
              <Lottie
                animationData={paymentStatus === "success" ? sucessslottie : failurelottie}
                style={{ width: 200, height: 200 }}
              />
            </div>
            <div className="text-center">
              <h2 className={`text-2xl font-semibold mb-1 ${paymentStatus === "success" ? "text-[#575757]" : "text-[#C84E4E]"}`}>
                {!isHindiLang 
                  ? (paymentStatus === "success" ? "Payment Successful" : "Payment failed") 
                  : (paymentStatus === "success" ? "भुगतान सफल हुआ।" : "भुगतान असफल हुआ।")}
              </h2>
              <p className="text-sm text-[#989898] font-normal">
                {!isHindiLang 
                  ? (paymentStatus === "success" ? "Your transaction was successful." : "Your transaction was unsuccessful.") 
                  : (paymentStatus === "success" ? "आपका लेन-देन सफल रहा।" : "आपका लेन-देन असफल रहा।")}
              </p>
            </div>

            <button
              onClick={paymentStatus === "success" ? handleStartTest : handleCloseModal}
              className="mt-4 px-4 py-2 bg-[#72B53D] text-white font-semibold rounded-full w-full"
            >
              {!isHindiLang 
                ? (paymentStatus === "success" ? "Start Test" : "Try again") 
                : (paymentStatus === "success" ? "परीक्षण शुरू करें" : "फिर से प्रयास करें।")}
            </button>
          </div>
        </div>
      )}
      </div>
    </>
  );
};

export default SwaasaPaymentFlow;
