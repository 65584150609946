import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_CONTENT_URL } from "../../utils/ApiConstants";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
const BreathingExerciseFAQ = () => {
  const [openIndex, setOpenIndex] = useState(0);
  const [faqData, setFaqData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const accessToken = localStorage.getItem("accesstoken");

  useEffect(() => {
    const fetchFaq = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.post(
          `${BASE_CONTENT_URL}/api/v2/landing-page-faq`,
          { module_name: "BREATHING_EXERCISE" },
          {
            headers: {
              "Content-Type": "application/json",
              accesstoken: accessToken,
            },
          }
        );
        setFaqData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Axios error:", error);
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchFaq();
  }, [accessToken]);


  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };
  // const parseHtmlContent = (htmlString) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlString, 'text/html');
  //   return doc.body.textContent || "";
  // };
  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error}</div>;
  const handleFAQClick = (faqTitle,index) => {
    sendEventsToAnalyticsSystems("exercise_subs_tap", {
      TapType: "FAQ click",
      FAQTapValue: faqTitle,
      utm_source  :'breathing_exercise_web'
    },localStorage.getItem("secondary_uuid"));
    
    // Toggle the accordion after sending the event
    toggleAccordion(index);
  };
  return (
    <>
    <h2 className="text-xl font-bold p-6">Frequently asked questions</h2>
      <div className="w-full max-w-md mx-auto bg-white px-4 rounded-lg">
        {faqData?.data.map((item, index) => (
          <div
            key={index}
            className={`border   ${
              index === 0 ? "rounded-t-lg" : ""
            } ${index === faqData?.data?.length - 1 ? "rounded-b-lg" : ""}`}
          >
            <button
              className={`w-full py-4 px-6 text-left flex justify-between items-center
                ${index === 0 ? "rounded-t-lg" : ""}
                ${
                  index === faqData?.data?.length - 1 && openIndex !== index
                    ? "rounded-b-lg"
                    : ""
                }`}
                onClick={() => handleFAQClick(item.question,index)}
            >
              <span className="font-normal text-[#6C6C6C] text-sm">{item?.question}</span>
              <span className="text-xl text-[#6C6C6C]">{openIndex === index ? "−" : "+"}</span>
            </button>
            {openIndex === index && (
              <div
                className={`px-6 pb-4 ${
                  index === faqData?.data?.length - 1 ? "rounded-b-lg" : ""
                }`}
              >
                <p className="font-normal text-[#060606] text-sm">
                  <div dangerouslySetInnerHTML={{ __html: item.answer }}
                  className="break-words whitespace-normal"
                  />
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default BreathingExerciseFAQ;
