import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { format } from 'date-fns'
import sanitizeHtml from 'sanitize-html';
import Share from '../../assests/icons/whatsapp.png';
import YouTube from 'react-youtube'
import { cn } from '../../utils/common'
import InstallAppHeader from '../exercise/InstallAppHeader'

const ContentID = () => {
  const { id } = useParams()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();

  const extractYoutubeId = (url) => {
    if (url?.length < 1 || !url) {
      return '';
    }
    const regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|shorts\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
    const checkMatch = url?.match(regExp);
    return checkMatch?.length > 0 ? checkMatch[1] : '';
  };

  const isPortrait = data?.content_detail?.youtube_url?.includes('portrait');
  const contentTypeIsVideo = data?.content_detail?.content_type === "VIDEO";

  const fetchData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_CONTENT_BASE_URL_TEST}/api/v1/webview/content/details`, { content_id: id })
      if (response?.data?.response?.code === 200) {
        setData(response?.data?.data)
        setLoading(false)
      } else {
        navigate("/")
        setLoading(false)
      }
    } catch (error) {
      navigate("/")
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (!id) return
    fetchData();
  }, [id]);


  return (
    <>
      <Helmet>
        {data?.content_detail?.content_title && <title>{data?.content_detail?.content_title}</title>}
        {data?.content_detail?.content_title && <meta name="title" content={data?.content_detail?.content_title} />}
        {data?.content_detail?.thumbnail_image && <meta name="og:image" content={`${process.env.REACT_APP_S3_BUCKET_URL_CONTENT}/${data.content_detail.thumbnail_image}`} />}
      </Helmet>
      <InstallAppHeader />
      <section className={cn(contentTypeIsVideo &&'bg-black relative h-[100svh]')}>
        <div className={cn(contentTypeIsVideo && "flex justify-center items-center h-[calc(100%_-_150px)]", "max-w-lg mx-auto pt-[60px]")}>
          {
            loading ?
              <div className='w-5/6 aspect-video animate-pulse bg-gray-400 mx-auto mt-5 rounded-md shadow-sm' /> :
              <>
                {
                  data?.content_detail?.thumbnail_image && !contentTypeIsVideo && (
                    <figure className='w-full aspect-video'>
                      <img className='object-cover w-full h-full' src={`${process.env.REACT_APP_S3_BUCKET_URL_CONTENT}/${data.content_detail.thumbnail_image}`} alt="thumbnail" />
                    </figure>
                  )
                }
                {
                  !contentTypeIsVideo ? (
                    <div className='py-4 w-5/6 mx-auto'>
                      {data?.content_detail?.created_on && <span className='text-gray-500 text-sm'>{format(new Date(data.content_detail.created_on), 'dd MMM, yyyy')}</span>}
                      {data?.content_detail?.content_title && <h2 className={cn(contentTypeIsVideo && 'absolute bottom-10 left-5 z-20 text-white', 'text-2xl font-bold w-4/5')}>{data.content_detail.content_title}</h2>}
                      {data?.content_detail?.html_contents && <div className='[&_a]:text-blue-500 [&_a]:underline prose [&_*]:my-0' dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.content_detail.html_contents) }} />}
                    </div>
                  ) :
                    (
                      <>
                        {data?.content_detail?.content_title && <h2 className={cn(contentTypeIsVideo && 'absolute bottom-10 left-5 z-20 text-white overflow-hidden line-clamp-3', 'text-2xl font-bold w-[75%]')}>{data.content_detail.content_title}</h2>}
                      </>
                    )
                }
                {
                  contentTypeIsVideo && data?.content_detail?.endless_scroll_media && !data?.content_detail?.youtube_url &&
                  <video
                    className='w-full h-full'
                    src={`${process.env.REACT_APP_S3_BUCKET_URL_CONTENT}/${data?.content_detail?.endless_scroll_media}`}
                    playsInline
                    loop
                    controls
                    controlsList='nodownload noplaybackrate'
                  />
                }
                {
                  contentTypeIsVideo && data?.content_detail?.youtube_url && (
                    <YouTube
                      videoId={extractYoutubeId(data?.content_detail?.youtube_url)}
                      className={cn(isPortrait ? 'h-full' : 'aspect-video', 'w-full')}
                      opts={{
                        height: '100%',
                        width: '100%',
                        playerVars: {
                          autoplay: 0,
                          mute: 0,
                          controls: 1,
                          playsinline: 1,
                          rel: 0,
                          showinfo: 1,
                        }
                      }}
                    />
                  )
                }
              </>
          }
        </div>
      </section>
      <a className='fixed bottom-10 right-5 shadow-md p-3 rounded-full bg-[#40C351]' href={`https://api.whatsapp.com/send?text=${data?.content_detail?.content_title}%20${window.location.href}`} target="_blank" rel="noopener noreferrer">
        <img className='w-8 h-8' src={Share} alt="Share" />
      </a>
    </>
  )
}

export default ContentID