import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import crossimg from '../../assests/images/x-button.png';

const CustomEducatorSelect = ({ 
  educatorData, 
  onSelect, 
  isHindiLang = false,
  placeholderEn = "Select Invite Code", 
  placeholderHi = "आमंत्रण कोड चुनें" 
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEducator, setSelectedEducator] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  // Filter options based on search term
  useEffect(() => {
    if (searchTerm) {
      const filtered = educatorData.filter(educator => 
        educator.educator_code.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(educatorData);
    }
  }, [searchTerm, educatorData]);

  // Handle outside click to close dropdown but keep selected value
  useEffect(() => {
    const handleClickOutside = (event) => {
        if (
            dropdownRef.current && 
            !dropdownRef.current.contains(event.target) &&
            inputRef.current && 
            !inputRef.current.contains(event.target)
        ) {
            setIsDropdownOpen(false); 
            if (selectedEducator) { 
                setSearchTerm(selectedEducator.educator_code); 
            } 
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [selectedEducator]);
  // Handle selection of an educator
  const handleSelect = (educator) => {
    setSelectedEducator(educator);
    setSearchTerm(educator.educator_code);
    setIsEditable(true); // Reset editability after selection
    onSelect(educator);
    setIsDropdownOpen(false); // Close dropdown after selection
  };

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsDropdownOpen(true); // Open dropdown on input change
  };

  // Handle input click to enable editing and focus
  const handleInputClick = () => {
    if (selectedEducator) {
      setIsEditable(true);
      setIsDropdownOpen(true); 
      // Focus the input element after a short delay
      setTimeout(() => {
        inputRef.current.focus(); 
      }, 50); 
    }
  };

  // Clear selection
  const clearSelection = () => {
    setSelectedEducator(null);
    setSearchTerm('');
    setIsEditable(false);
    onSelect(null);
    setIsDropdownOpen(false); // Close dropdown on clear selection
  };

  // const handleFocus = () => {
  //   if (selectedEducator) {
  //     setIsEditable(true);
  //     setIsDropdownOpen(true);
  //   }
  // };

  return (
    <div className="relative w-full">
      <div className="flex items-center">
        <div className="relative w-full">
          <input
            ref={inputRef}
            type="tel"
            value={searchTerm} 
            onChange={handleInputChange}
            onClick={handleInputClick} 
            // onFocus={handleFocus} 
            placeholder={isHindiLang ? placeholderHi : placeholderEn}
            readOnly={selectedEducator && !isEditable}
            className={`
              w-full h-[60px] px-3 border rounded-lg 
              bg-[#FBFBFB] border-[#EBEBEB] 
              focus:outline-none focus:border-[#72B53D]
              ${selectedEducator && !isEditable 
                ? 'cursor-pointer' 
                : 'cursor-text'}
            `}
          />
          {selectedEducator && searchTerm && (
            <button 
              onClick={clearSelection}
              className="absolute h-4 w-4 right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-red-500"
            >
              <img src={crossimg}/>
            </button>
          )}
        </div>
      </div>

      {isDropdownOpen && filteredOptions.length > 0 && (
        <div 
          ref={dropdownRef}
          className="absolute z-10 w-full max-h-60 overflow-y-auto bg-white border rounded-lg shadow-lg mt-1" // Use overflow-y-auto for vertical scrolling
        >
          {filteredOptions.map((educator) => (
            <div
              key={educator.educator_code}
              onClick={() => handleSelect(educator)}
              className="p-2 hover:bg-gray-100 cursor-pointer"
            >
              {educator.educator_code}
            </div>
          ))}
        </div>
      )}

      {isDropdownOpen && filteredOptions.length === 0 && (
        <div className="absolute z-10 text-center w-full bg-white border rounded-lg shadow-lg mt-1 p-2 text-gray-500">
          {!isHindiLang ? "No invite codes found" : "कोई आमंत्रण कोड नहीं मिला"}
        </div>
      )}
    </div>
  );
};

CustomEducatorSelect.propTypes = {
  educatorData: PropTypes.arrayOf(
    PropTypes.shape({
      educator_code: PropTypes.string.isRequired
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  isHindiLang: PropTypes.bool,
  placeholder: PropTypes.string
};

export default CustomEducatorSelect;